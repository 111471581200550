import axios from 'axios';

// функции для работы со страницей usergroups исключительно
export default {
  getGroupList: (uid) => new Promise((res, rej) => {
    axios.get(`/groupslist/getlists?uid=${uid}`).then((resp) => {
      res(resp.data);
    }).catch((err) => {
      rej(err);
    });
  }),

  createNewList: ({ name, id }) => new Promise((res, rej) => {
    axios.get(`/groupslist/new?name=${name}&id=${id}`).then((resp) => {
      res(resp.data);
    }).catch((err) => {
      rej(err);
    });
  }),

  updateList: ({ name, lid }) => new Promise((res, rej) => {
    axios.get(`/groupslist/upname?name=${name}&lid=${lid}`).then((resp) => {
      res(resp.data);
    }).catch((err) => {
      rej(err);
    });
  }),

  deleteList: ({ lid, name }) => new Promise((res, rej) => {
    axios.get(`/groupslist/del?lid=${lid}&name=${name}`).then((resp) => {
      res(resp);
    }).catch((err) => {
      rej(err);
    });
  }),

  addGroupInList: ({ gid, lid }) => new Promise((res, rej) => {
    axios.get(`/groupslist/add?gid=${gid}&lid=${lid}`).then((resp) => {
      res(resp.data);
    }).catch((err) => {
      rej(err);
    });
  }),

  deleteGroupFromList: ({ gid = '', lid = '' }) => new Promise((res, rej) => {
    if (gid === '' || lid === '') {
      return;
    }
    axios.get(`/groupslist/dgr?gid=${gid}&lid=${lid}`).then((resp) => {
      res(resp.data);
    }).catch((err) => {
      rej(err);
    });
  }),

  // Метод получения ссылки на подтверждение
  confirmGroup({ gid, uid }) {
    return new Promise((res, rej) => {
      axios.get(`/uniq/create?gid=${gid}&uid=${uid}`).then((responce) => {
        res(responce.data);
      }).catch((err) => {
        rej(err);
      });
    });
  },

  submitAdminsGroup({ gid, uid }) {
    return new Promise((res, rej) => {
      axios.get(`/mygroups/add?gid=${gid}&uid=${uid}`).then((responce) => {
        res(responce.data);
      }).catch((err) => {
        rej(err);
      });
    });
  },
};
