export const errorList = {
  msgError: 'ERROR MESSAGE:',

  // errors auth
  emptyString: 'Пустое поле',
  emailError: 'Введите корректный адрес эл. почты',
  passwordError: 'Пароли не совпадают',
  passwordPolicy: `Пароль не соответствует требованиям:
  * строка содержит хотя бы одно число; 
  * строка содержит хотя бы одну латинскую букву в верхнем регистре;
  * строка состоит не менее, чем из 6 вышеупомянутых символов.
  `,
};

export const authList = {
  // pages
  authPage: 'auth-page',
  registrationPage: 'registration-page',

  // text
  regText: 'Регистрация',
  emailText: 'Эл. адрес',
  userNameText: 'Имя пользователя',
  userEmailText: 'Эл. адрес',
  passwordText: 'Пароль',
  confirmPasswordText: 'Подтверждение пароля',
  forgotPwdLink: 'Забыли ваш пароль?',
  sucsessRegistration: 'Регистрация прошла успешно',

  authText: 'Аутентификация',

  // buttons
  login: 'Войти',
  registration: 'Регистрация',

  // links name
  userPage: 'Личный кабинет',
  exitPage: 'Выйти',
};

export const currentPath = '';
