import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';
import Main from '../views/vkMenu.vue';

const routes = [
  {
    path: '',
    name: 'main',
    component: Main,
    redirect: { name: 'communities' },
    meta: { layout: 'default' },
    children: [
      {
        name: 'communities',
        path: '/communities',
        component: () => import('../views/vkMenu/Communities.vue'),
      },
      // {
      //   name: 'user-communities',
      //   path: '/communities/:userId',
      //   component: () => import('../views/vkMenu/Communities.vue'),
      // },
      {
        name: 'communities-admin',
        path: '/communities&id=:nameId',
        component: () => import('../views/vkMenu/Communities.vue'),
      },
      {
        name: 'communities-catalog',
        path: '/communities/cat/:catalogsId',
        component: () => import('../views/vkMenu/Communities.vue'),
      },
      {
        name: 'communities-admin-catalog',
        path: '/communities&id=:nameId/cat/:catalogsId',
        component: () => import('../views/vkMenu/Communities.vue'),
      },
      // {
      //   name: 'user-communities-catalog',
      //   path: '/communities/:userId/cat/:catalogsId',
      //   component: () => import('../views/vkMenu/Communities.vue'),
      // },
      // {
      //   name: 'user-communities-admin',
      //   path: '/communities&id=:nameId/:userId',
      //   component: () => import('../views/vkMenu/Communities.vue'),
      // },
      // {
      //   name: 'user-communities-admin-catalog',
      //   path: '/communities&id=:nameId/:userId/cat/:catalogsId',
      //   component: () => import('../views/vkMenu/Communities.vue'),
      // },

      {
        name: 'changes',
        path: '/changes',
        component: () => import('../views/vkMenu/Changes.vue'),
      },
      {
        name: 'administrator',
        path: '/administrator',
        component: () => import('../views/vkMenu/Administrators.vue'),
      },
      // changes page
      // {
      //   name: 'user-changes',
      //   path: '/changes/:userId',
      //   component: () => import('../views/vkMenu/Changes.vue'),
      // },
      // // admin pages
      // {
      //   name: 'user-administrator',
      //   path: '/administrator/:userId',
      //   component: () => import('../views/vkMenu/Administrators.vue'),
      // },
      {
        name: 'public',
        path: '/public',
        component: () => import('../views/vkMenu/PublicSale.vue'),
      },
      // {
      //   name: 'user-public',
      //   path: '/public/:userId',
      //   component: () => import('../views/vkMenu/PublicSale.vue'),
      // },
    ],
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutPage.vue'),
    meta: { layout: 'empty' },
  },
  {
    path: '/account/:userId',
    name: 'user-panel',
    component: () => import('../views/user/UserPage.vue'),
    meta: { layout: 'default' },
    beforeEnter: (to, from, next) => {
      const isAuth = store.getters['auth/isAuthenticated'];
      if (isAuth) {
        next();
      } else {
        next({ name: 'communities' });
      }
    },
  },
  {
    path: '/crm',
    name: 'mega-admin',
    component: () => import('../views/admin/ControlPage.vue'),
    // redirect: { name: 'mega-admins' },
    meta: { layout: 'admin' },
    children: [
      {
        path: 'login',
        name: 'auth-admins',
        component: () => import('../views/admin/AuthPage.vue'),
      },
      {
        path: '',
        name: 'mega-admins',
        component: () => import('../views/admin/AdminPage.vue'),
        beforeEnter: (to, from, next) => {
          const isKey = store.getters['auth/checkCRMKey'];
          if (isKey) {
            next();
          } else {
            next({ name: 'auth-admins' });
          }
        },
      },
      {
        path: 'edit',
        name: 'edit-page',
        component: () => import('../views/admin/BlackList.vue'),
        beforeEnter: (to, from, next) => {
          const isKey = store.getters['auth/checkCRMKey'];
          if (isKey) {
            next();
          } else {
            next({ name: 'auth-admins' });
          }
        },
      },
      {
        path: 'sale',
        name: 'sale-page',
        component: () => import('../views/admin/AdminSale.vue'),
        beforeEnter: (to, from, next) => {
          const isKey = store.getters['auth/checkCRMKey'];
          if (isKey) {
            next();
          } else {
            next({ name: 'auth-admins' });
          }
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'main' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!store.getters['auth/isAuthenticated'] && to.name.includes('public')) {
    next({ name: 'communities' });
    return;
  }

  if (to.name.includes('communities') && to.params.nameId) {
    store.commit('vkRequests/setAdminsId', to.params.nameId);
  } else if (to.params.nameId) {
    store.commit('vkRequests/setAdminsId');
  }

  if (!store.getters['auth/isAuthenticated'] && window.location.href.indexOf('code=') !== -1) {
    const currentValue = window.location.search.replace(/\?/, '').split('=');
    const getParams = { [currentValue[0]]: currentValue[currentValue.length - 1] };

    store.dispatch('auth/requestToken', getParams.code);
  }
  next();
});

export default router;
