import axios from 'axios';
// import router from '@/router';

// const USER_REQUEST = 'user';
const AUTH_REQUEST = 'login';
const AUTH_VK_REQUEST = 'vklogin';
const AUTH_SUCCESS = 'sucsess';
const AUTH_ERROR = 'error';
const AUTH_LOGOUT = 'logout';
const AUTH_REGISTRATION = 'registration';
const AUTH_VK_CHECK = 'requestToken';

const SET_CRM_KEY = 'enterByCRMKey';

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('user-token') || '',
    user: localStorage.getItem('user-name') || '',
    userId: localStorage.getItem('user-id') || '',
    mail: localStorage.getItem('user-mail') || '',
    crmkey: sessionStorage.getItem('crm-key') || '',

    status: '',
  },

  mutations: {
    [AUTH_REQUEST]: (state) => {
      state.status = 'loading';
    },
    [AUTH_SUCCESS]: (state, {
      token, name, email, userId,
    }) => {
      state.status = 'success';
      state.token = token;
      state.user = name;
      state.mail = email;
      state.userId = userId;
    },
    [AUTH_ERROR]: (state) => {
      state.status = 'error';
    },
    [AUTH_LOGOUT]: (state) => {
      state.status = '';

      state.token = '';
      state.user = '';
      state.mail = '';
    },
    [SET_CRM_KEY]: (state, { key, isSet }) => {
      if (isSet) {
        state.crmkey = key;
        sessionStorage.setItem('crm-key', key);
      } else {
        state.crmkey = '';
        sessionStorage.removeItem('crm-key');
      }
    },
  },

  actions: {
    [AUTH_REQUEST]: ({ commit }, user) => new Promise(
      (resolve, reject) => { // The Promise used for router redirect in login
        commit(AUTH_REQUEST);

        axios.post('/login', user)
          .then((resp) => {
            if (resp.data.error) {
              commit(AUTH_ERROR, resp.data.error);

              reject(resp.data.error);
              return;
            }

            // console.log('dsfdsf', resp.data);
            const { token } = resp.data;
            const { name } = resp.data;
            const { email } = resp.data;
            const { id: userId } = resp.data;

            // store the token in localstorage
            localStorage.setItem('user-token', token);
            localStorage.setItem('user-name', name);
            localStorage.setItem('user-mail', email);
            localStorage.setItem('user-id', userId);

            // console.log('userId', userId);
            commit(AUTH_SUCCESS, {
              token, name, email, userId,
            });

            axios.defaults.headers.Authorization = `Bearer ${resp.data.token}`;

            resolve(resp.data);
          })
          .catch((err) => {
            commit(AUTH_ERROR, err);

            // if the request fails, remove any possible user token if possible
            localStorage.removeItem('user-token');
            localStorage.removeItem('user-name');
            localStorage.removeItem('user-mail');

            reject(err);
          });
      },
    ),

    [AUTH_LOGOUT]: ({ commit }) => new Promise((resolve) => {
      commit(AUTH_LOGOUT);

      localStorage.removeItem('user-token'); // clear your user's token from localstorage
      localStorage.removeItem('user-name');
      localStorage.removeItem('user-mail');
      resolve();
    }),

    [AUTH_VK_REQUEST]: ({ commit }, user) => new Promise(
      (resolve, reject) => {
        // console.log('before request', user);
        axios.post('/vklogin', { token: user.access_token })
          .then((resp) => {
            // console.log('check USERID', resp);
            if (resp.data.error) {
              commit(AUTH_ERROR, resp.data.error);

              reject(resp.data.error);
              return;
            }

            const { token } = resp.data;
            const { name } = resp.data;
            const { email } = resp.data;
            const { id: userId } = resp.data;

            // store the token in localstorage
            localStorage.setItem('user-token', token);
            localStorage.setItem('user-name', name);
            localStorage.setItem('user-mail', email);
            localStorage.setItem('user-id', userId);

            commit(AUTH_SUCCESS, {
              token, name, email, userId,
            });

            axios.defaults.headers.Authorization = `Bearer ${resp.data.token}`;

            resolve(resp.data);
          })
          .catch((err) => {
            commit(AUTH_ERROR, err);

            // if the request fails, remove any possible user token if possible
            localStorage.removeItem('user-token');
            localStorage.removeItem('user-name');
            localStorage.removeItem('user-mail');

            reject(err);
          });
      },
    ),

    [AUTH_REGISTRATION]: (ctx, user) => new Promise((resolve, reject) => {
      axios.post('/reg', user).then((response) => {
        if (response.data.message !== 'success') {
          reject(response.data.error);
          return;
        }

        resolve(response.data.message);
      }).catch((error) => {
        console.log('Error', error, error.response);
        reject(error.response.data);
      });
    }),

    [AUTH_VK_CHECK]: ({ dispatch }, tiket) => new Promise((resolve, reject) => {
      const requestResult = `/access_token?client_id=8192905&client_secret=Co4DgvwEwzJz2WMtfugu&redirect_uri=https://adminota.ru/&code=${tiket}`;
      const formData = new FormData();
      formData.append('code', tiket);

      axios.post(requestResult).then((response) => {
        // console.log('vk response', response);
        dispatch(AUTH_VK_REQUEST, response.data);

        resolve(response.data);
      }).catch((error) => {
        console.log('nk', error, error.response);
        reject();
      });
    }),

    [SET_CRM_KEY]: ({ commit }, key) => new Promise((resolve, reject) => {
      axios.get(`/superadmin/getbl?key=${key}`).then((responce) => {
        if (responce.data.error) {
          commit(SET_CRM_KEY, { key, isSet: false });
          reject(responce.data.error);
          return;
        }

        commit(SET_CRM_KEY, { key, isSet: true });
        resolve('sucsess');
      }).catch((err) => {
        commit(SET_CRM_KEY, { key, isSet: false });
        reject(err);
      });
    }),
  },

  getters: {
    isAuthenticated: (state) => !!state.token,
    authStatus: (state) => state.status,
    errorDesc: (state) => state.errorDesc,

    authParams: (state) => ({
      token: state.token, user: state.user, mail: state.mail, userId: state.userId,
    }),

    checkCRMKey: (state) => state.crmkey,
  },
};
