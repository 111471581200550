<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import AdminLayout from '@/layouts/AdminLayout.vue';
import calc from '@/utils/eee';

export default {
  components: {
    EmptyLayout,
    DefaultLayout,
    AdminLayout,
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || 'empty'}-layout`;
    },
  },
  watch: {
    $route(to) {
      let route = '';
      const { user } = this.$store.getters['auth/authParams'];

      switch (true) {
        case to.name.includes('communities'):
        case to.name.includes('changes'):
        case to.name.includes('administrator'):
        case to.name.includes('public'):
          route = calc.calcNewPath(this.$route);

          if (this.$store.getters['auth/isAuthenticated']) {
            route.params.userId = user;
            // route.name = `user-${route.name}`;
            route.name = `${route.name}`;
          }

          this.$router.push(route);
          break;
        // case to.name.includes('mega'):
        //   this.$router.push(to.name);
        //   break;
        default:
      }
    },
  },

  created() {
    // eslint-disable-next-line max-len
    // localStorage.setItem('user-token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3MDc1ODE0NSwianRpIjoiNTI1ZTQ1N2EtODkzNi00OTQ1LThkYTktNDA5NDgxNzk2ZWY4IiwidHlwZSI6ImFjY2VzcyIsInN1YiI6ImU2Y2QzMmViLWVkNTQtNGQ5My04YzBjLTJhNmY4NTYxYTE4NiIsIm5iZiI6MTY3MDc1ODE0NSwiZXhwIjoxNjcwODQ0NTQ1fQ.MieIV_Mvc56VkIDhH-zVp4DcJC7Fq-HdcHXYyZ_mBa8');
    // localStorage.setItem('user-name', 'Zek Cat');
    // localStorage.setItem('user-id', 507628103);
    // localStorage.setItem('user-mail', 'vk_auth');
    // axios.interceptors.response.use(
    //   undefined,
    //   (err) => new Promise(function (resolve, reject) {
    //     if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
    //       // if you ever get an unauthorized, logout the user
    //       this.$store.dispatch(AUTH_LOGOUT);
    //       // you can also redirect to /login if needed !
    //     }
    //     throw err;
    //   }),
    // );
  },
};
</script>

<style lang="scss">
@import "./assets/css/values.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  // height: 100vh;
  background: #fff;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

a {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;

  color: #45688e;

  box-sizing: border-box;
  border-radius: 10px;

  font-size: 20px;
  line-height: 22px;

  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>
