import axios from 'axios';
// import { errorList } from '@/assets/js/values';
// import router from '@/router';

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('user-token') || '',
    status: '',

    loginState: false,
    errorInfo: '',

    registrationState: '',
    isChechState: true,
    requestCalls: 0,
    updateVK: false,
    userName: '',
  },

  mutations: {
    //  [AUTH_REQUEST]: (state) => {
    //    state.status = 'loading';
    //  },
    //  [AUTH_SUCCESS]: (state, token) => {
    //    state.status = 'success';
    //    state.token = token;
    //  },
    //  [AUTH_ERROR]: (state) => {
    //    state.status = 'error';
    //  },

    setLogonState(state, value = false) {
      state.loginState = value;
    },
    clearError(state) {
      state.errorInfo = '';
    },
    clearRegistration(state) {
      state.registrationState = '';
    },
    dropVKUpdate(state) {
      state.updateVK = false;
    },
  },

  actions: {
    async checkAuth({ state, dispatch, commit }) {
      await dispatch('checkTiketInRoute');
      state.isChechState = true;

      if (localStorage.getItem('token') === undefined || localStorage.getItem('token') === null) {
        // console.log('checkAuth returne');
        state.isChechState = false;
        commit('setLogonState', false);
        return;
      }

      const checkResult = {
        token: localStorage.getItem('token'),
        email: localStorage.getItem('userMail'),
      };

      state.userName = localStorage.getItem('userName');

      // console.log('checkResult');

      await axios.get(`/checktoken?token=${checkResult.token}&email=${checkResult.email}`).then((response) => {
        // console.log('response.data checktoken', response.data);
        if (response.data.message === 'success') {
          commit('setLogonState', true);
          // console.log('checkResult');
          axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

          return;
        }

        localStorage.removeItem('token');
        localStorage.removeItem('userName');
        localStorage.removeItem('userMail');
        state.userName = '';
        axios.defaults.headers.Authorization = null;

        commit('setLogonState', false);
        dispatch('vkRequests/requestCurrentPage', null, { root: true });
      }).catch((error) => {
        console.log('Error', error, error.response, axios.defaults, `/checktoken?token=${checkResult.token}&email=${checkResult.email}`);

        localStorage.removeItem('token');
        localStorage.removeItem('userName');
        localStorage.removeItem('userMail');
        state.userName = '';
        axios.defaults.headers.Authorization = null;

        commit('setLogonState', false);
        dispatch('vkRequests/requestCurrentPage', null, { root: true });
      }).then(() => {
        state.isChechState = false;
      });
    },

    async checkTiketInRoute({ state, dispatch, commit }) {
      // console.log('checkTiketInRoute', window.location.href);
      if (window.location.href.indexOf('communities&id=') !== -1) {
        const currentValue = window.location.href.replace(/\?/, '').split('=');
        // console.log('currentValue[
        commit('vkRequests/setAdminsId', currentValue[currentValue.length - 1], { root: true });
        return;
      }
      if (window.location.href.indexOf('code=') !== -1) {
        const currentValue = window.location.search.replace(/\?/, '').split('=');
        const getParams = { [currentValue[0]]: currentValue[currentValue.length - 1] };
        state.requestCalls += 1;

        await dispatch('requestToken', getParams.code).then((response) => {
          dispatch('vklogin', response.data);
        }).catch((error) => {
          console.log('r', error, error.response);
        });
      }

      // state.stackCalls.push('emmit');
    },

    async requestToken(ctx, tiket) {
      const requestResult = `/access_token?client_id=8192905&client_secret=Co4DgvwEwzJz2WMtfugu&redirect_uri=https://adminota.ru/&code=${tiket}`;
      // const requestResult = '';
      const formData = new FormData();
      formData.append('code', tiket);

      return axios.post(requestResult);
    },

    async loginRequest({ commit, state, dispatch }, user) {
      await axios.post('/login', user).then((response) => {
        if (response.data.error) {
          state.errorInfo = response.data.error;
          commit('setLogonState', false);
          return;
        }

        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userName', response.data.name);
        localStorage.setItem('userMail', response.data.email);
        state.userName = response.data.name;
        axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;

        commit('setLogonState', true);

        dispatch('vkRequests/requestCurrentPage', null, { root: true });
      }).catch((error) => {
        console.log('Error', error);
        commit('setLogonState', false);
      });
    },

    async vklogin({ state, commit, dispatch }, user) {
      await axios.post('/vklogin', { token: user.access_token })
        .then((response) => {
          if (!response.data.token) {
            commit('setLogonState', false);
            return;
          }

          localStorage.setItem('token', response.data.token);
          localStorage.setItem('userName', response.data.name);
          localStorage.setItem('userMail', response.data.email);
          state.userName = response.data.name;
          axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;
          commit('setLogonState', true);

          dispatch('vkRequests/requestCurrentPage', null, { root: true });
          // state.updateVK = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    registrationRequest({ state }, user) {
      axios.post('/reg', user).then((response) => {
        if (response.data.message !== 'success') {
          state.errorInfo = response.data.error;
          return;
        }
        state.registrationState = 'success';
      }).catch((error) => {
        console.log('Error', error, error.response);

        state.errorInfo = error.response.data;
      });
    },

    logout({ state, commit }) {
      localStorage.removeItem('token');
      localStorage.removeItem('userName');
      localStorage.removeItem('userMail');

      state.userName = '';

      axios.defaults.headers.Authorization = null;

      commit('setLogonState', false);

      // console.log('LOGUOUTTT', localStorage.getItem('userName'));
      // dispatch('vkRequests/requestCurrentPage', null, { root: true });
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.token,
    authStatus: (state) => state.status,
    checkLoginState(state) {
      return state.loginState;
    },

    getErrorInfo(state) {
      return state.errorInfo;
    },

    getRegistrationState(state) {
      return state.registrationState;
    },

    getChechState(state) {
      return state.isChechState;
    },

    isUpdateVK(state) {
      return state.updateVK;
    },

    getUserName(state) {
      return state.userName;
    },
  },
};
