import PublicSale from '@/api/publicSale';

const publicSale = new PublicSale();

export default {
  namespaced: true,

  state: {
    createdClass: publicSale,
    groups: [],
    isLoad: false,
    category: [],
    sort: publicSale.sort,
  },
  mutations: {
    updateGroups(state, newGroups) {
      state.groups = newGroups;
    },

    addGroups(state, newGroups) {
      state.groups = [...state.groups, ...newGroups];
    },

    updateCategoryes(state) {
      state.category = state.createdClass.category;
    },
  },
  actions: {
    updateGroupsByFilter({ state, dispatch }, { groupName, settingName }) {
      state.createdClass.updateByFilter(groupName, settingName);

      dispatch('requestGroups');
    },

    updateGroupsBySort({ state, dispatch }, { filtPar, sortBy }) {
      state.createdClass.updateFilterSort(filtPar, sortBy);

      dispatch('requestGroups');
    },

    requestGroups({ state, commit, rootGetters }) {
      state.isLoad = false;
      const { userId: uid } = rootGetters['auth/authParams'];

      state.createdClass.takeGroupsForSale(uid).then((resp) => {
        commit('updateCategoryes');
        commit('updateGroups', resp);
        state.isLoad = true;
      }).catch(() => {
        state.isLoad = true;
      });
    },

    requestGroupByInfinity({ state, commit, rootGetters }) {
      state.createdClass.updateCountToFrom();

      // state.isLoad = false;
      const { userId: uid } = rootGetters['auth/authParams'];

      state.createdClass.takeGroupsForSale(uid).then((resp) => {
        commit('updateCategoryes');
        commit('addGroups', resp);
        // state.isLoad = true;
      }).catch(() => {
        // state.isLoad = true;
      });
    },

    // пока не используется
    async resetInfinity({ state }) {
      await state.createdClass.resetCountToFrom();
    },

    takeFavoriteGroups({ state, commit, rootGetters }) {
      state.isLoad = false;
      const { userId: uid } = rootGetters['auth/authParams'];

      state.createdClass.getFavoriteGroup(uid).then((resp) => {
        commit('updateGroups', resp);
        state.isLoad = true;
      }).catch(() => {
        state.isLoad = true;
      });
    },

    toogleFavoriteGroup({ state }, { gid, uid, isAdded = true }) {
      if (!isAdded) {
        return state.createdClass.delFavoriteGroup({ gid, uid });
      }

      return state.createdClass.addFavoriteGroup({ gid, uid });
    },

    takeAdminGroups({ state, commit, rootGetters }) {
      state.isLoad = false;
      const { userId: uid } = rootGetters['auth/authParams'];
      state.createdClass.takeAdminGroupsForSale(uid).then((resp) => {
        commit('updateGroups', resp);
        state.isLoad = true;
      }).catch(() => {
        state.isLoad = true;
      });
    },

    // доп функции для страницы админов
    deleteAdminsGroupFromSale({ state }, card) {
      return state.createdClass.deleteGroupBySale({ gid: card.id, uid: card.admin_id });
      // return Promise((resolve, reject) => {
      //   state.createdClass.deleteGroupBySale({ gid: card.gid, uid: card.gid }).then((resp) => {
      //     resolve(resp);
      //   }).catch((err) => {
      //     reject(err);
      //   });
      // });
    },
  },
  getters: {
    getGroups: (state) => state.groups,
    isPageLoad: (state) => state.isLoad,
    getCategoryes: (state) => state.category,
    getSort: (state) => state.sort,
  },
};
