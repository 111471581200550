export default {
  namespaced: true,

  state: {
    classInstanceList: {},
  },
  mutations: {
    addNewInstance(state, newClass) {
      state.classInstanceList = {
        ...state.classInstanceList,
        ...newClass,
      };
    },

    deleteInstance(state, currentClassName) {
      delete state.classInstanceList[currentClassName];
    },
  },
  actions: {
    addNewInstance({ commit }, newClass) {
      commit('addNewInstance', newClass);
    },
    deleteInstance({ commit }, currentClassName) {
      commit('deleteInstance', currentClassName);
    },
  },
  getters: {
    getClassInstanceList: (state) => state.classInstanceList,
  },
};
