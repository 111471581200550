<template>
  <div class="auth-panel">
    <template v-if="!checkAuthenticated">
      <div class="vk-auth">
        <p class="vk-auth__text" @click.prevent="openPage">
          Войти через :
        </p>
        <a class="vk-auth__container" @click.prevent="requestVKAuth()">
          <img src="/icons/common/vk.png" class="vk-auth__icon" />
        </a>
      </div>

      <!-- <a href="#" class="auth-panel__button" @click.prevent=
        "setCurrentPage(authList.authPage)">
        {{ authList?.login }}
      </a>

      <a
        href="#"
        class="auth-panel__button"
        @click.prevent="setCurrentPage(authList.registrationPage)"
      >
        {{ authList?.registration }}
      </a>-->

      <Teleport to="body">
        <component
          v-if="currentPage"
          :is="currentPage"
          class="modal"
          @closeWindow="closeWindow"
          @openAnotherPage="openPage"
        ></component>
      </Teleport>
    </template>

    <template v-else>
      <div class="auth-panel" @click.stop="tooglePannel">
        <img src="/icons/common/user-icon.svg" class="auth-panel__icon" />

        <a href="#" class="auth-panel__button" @click.prevent="">
          {{ takeUserName }}
        </a>
      </div>

      <menu-mobile v-if="mobileLanding && showPanel" class="menu__admin"></menu-mobile>

      <transition name="authmenu" appear>
        <auth-menu v-if="showMenu" class="auth-panel__menu" @close="tooglePannel"></auth-menu>
      </transition>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AuthPage from '@/components/common/authentication/AuthPage.vue';
// import RegistrationPage from '@/components/common/authentication/RegistrationPage.vue';
import AuthMenu from './AuthMenu.vue';
import MenuMobile from '@/components/menu/mobile/MenuMobile.vue';
import { authList } from '@/assets/js/values';

export default {
  components: {
    AuthMenu,
    MenuMobile,
    AuthPage,
  },
  data() {
    return {
      currentPage: '',
      username: '',
      authList,
      showMenu: false,
      timerId: 0,
      mobileLanding: false,
      showPanel: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'authParams']),

    checkAuthenticated() {
      return this.isAuthenticated;
    },

    takeUserName() {
      const { user } = this.authParams;
      return user;
    },
  },
  watch: {
    $route: {
      handler(to) {
        if (to.name === 'user-panel') {
          this.showPanel = true;
        } else {
          this.showPanel = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapMutations('adminRequest', ['setLogonState']),
    ...mapActions('adminRequest', ['checkAuth']),
    ...mapActions('routerControl', ['checkIsPageNeedSetRoute', 'setRoute']),

    async requestVKAuth() {
      const request = 'https://oauth.vk.com/authorize?client_id=8192905&display=popup&redirect_uri=https://adminota.ru/&scope=email&response_type=code';
      window.location.href = request;
    },

    // closePopup() {
    //   this.timerId = setTimeout(() => {
    //     this.showMenu = false;
    //   }, 1000);
    // },
    // stopClose() {
    //   clearTimeout(this.timerId);
    // },

    closeWindow(state = '') {
      this.currentPage = '';

      if (state === 'checkAuth') {
        this.checkAuth();
      }
    },

    setCurrentPage(pageName) {
      this.currentPage = pageName;
    },

    setEyeVisible() {
      if (this.isAuthenticated) {
        document.body.style.setProperty('--cell-eye', '60px');
      } else {
        document.body.style.setProperty('--cell-eye', '0px');
      }
    },

    resize() {
      if (window.innerWidth <= 1300) {
        this.mobileLanding = true;

        return;
      }

      this.mobileLanding = false;
    },

    openPage() {
      switch (this.currentPage) {
        case authList.authPage:
          this.currentPage = authList.registrationPage;
          break;
        default:
          this.currentPage = authList.authPage;
      }
    },

    tooglePannel() {
      if (this.$route.name === 'user') {
        return;
      }

      this.showMenu = !this.showMenu;
    },
  },

  mounted() {
    window.addEventListener('resize', this.resize);
    this.resize();
  },

  unmounted() {
    window.removeEventListener('resize', this.resize);
    this.mobileLanding = false;
  },
};
</script>

<style lang="scss" scoped>
.auth-panel {
  display: flex;
  align-items: center;
  position: relative;

  height: 100%;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    // width: 145px;
    height: 45px;

    padding: 0;
    // margin: 0 0 0 11px;
    margin: 0 8px;

    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &__icon {
    width: 25px;
    height: 25px;
  }

  &__menu {
    position: absolute;
    top: 65px;
    right: 0;
    z-index: 30;
  }
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 30;
  // opacity: 1;
}

.authmenu-enter-active,
.authmenu-leave-active {
  transition: opacity 0.3s ease;
}

.authmenu-enter-from,
.authmenu-leave-to {
  opacity: 0;
}

.vk-auth {
  display: flex;

  display: flex;
  justify-content: center;
  align-items: center;

  // .vk-auth__title
  &__title {
    font-size: 25px;
  }
  // .vk-auth__container
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    // background: #e1e1e1;
    box-sizing: border-box;
    height: auto;
    // padding: 8px;

    text-decoration: none;
    cursor: pointer;
    width: 45px;
    height: 45px;
  }

  // .vk-auth__icon
  &__icon {
    width: 100%;
    height: 100%;
    // margin: 0 15px 0 0;
  }

  // .vk-auth__text
  &__text {
    font-size: 25px;
  }
}
</style>
