export default {
  countCals: 0,
  calcNewPath(param) {
    // console.log('param', param);
    const { name, params } = param;

    const newPath = {
      name: '',
      params: {},
    };
    const pathName = name;

    let basePageName = this.getPageName(pathName);

    const { nameId, catalogsId } = params;

    // if (userId) {
    //   basePageName = `user-${basePageName}`;
    //   newPath.params.userId = userId;
    // }

    if (nameId) {
      basePageName = `${basePageName}-admin`;
      newPath.params.nameId = nameId;
    }

    if (catalogsId) {
      basePageName = `${basePageName}-catalog`;
      newPath.params.catalogsId = catalogsId;
    }

    newPath.name = basePageName;
    // console.log('checkss eee', newPath);
    return newPath;
  },

  getPageName(currentPage) {
    let baseName = '';
    switch (true) {
      case currentPage.includes('communities'):
        baseName = 'communities';
        break;
      case currentPage.includes('changes'):
        baseName = 'changes';
        break;
      case currentPage.includes('administrator'):
        baseName = 'administrator';
        break;
      case currentPage.includes('public'):
        baseName = 'public';
        break;
      default:
    }
    return baseName;
  },
};
