export default {
  // Отсюда берем только значения, по факту работа происходит с копиями
  namespaced: true,
  state: {
    // settings params
    period: [
      {
        id: 1,
        title: 'День',
        settingName: 'day',
        checked: true,
      },
      {
        id: 2,
        title: 'Неделя',
        settingName: 'week',
        checked: false,
      },
      {
        id: 3,
        title: 'Месяц',
        settingName: 'month',
        checked: false,
      },
    ],
    type: [
      {
        id: 1,
        title: 'Все',
        settingName: 'all',
        checked: true,
      },
      {
        id: 2,
        title: 'Паблики',
        settingName: 'page',
        checked: false,
      },
      {
        id: 3,
        title: 'Группы',
        settingName: 'group',
        checked: false,
      },
      {
        id: 4,
        title: 'События',
        settingName: 'event',
        checked: false,
      },
    ],
    status: [
      {
        id: 1,
        title: 'Все группы',
        settingName: 'empty',
        checked: true,
      },
      {
        id: 2,
        title: 'Открытые',
        settingName: 'Открытая группа',
        checked: false,
      },
      {
        id: 3,
        title: 'Закрытые',
        settingName: 'Закрытая группа',
        checked: false,
      },
    ],
    checkButtons: [
      {
        id: 1,
        title: 'Только официальные',
        name: 'verification',
        isChecked: false,
      },
      {
        id: 2,
        title: 'Показывать позицию',
        name: 'indexes',
        isChecked: true,
      },
      {
        id: 3,
        title: 'Добавить в избранное',
        name: 'favorites',
        isChecked: false,
      },
    ],

    checkButtonsSale: [
      {
        id: 1,
        title: 'Только официальные',
        name: 'verification',
        isChecked: false,
      },
      // {
      //   id: 2,
      //   title: 'Избранные группы',
      //   name: 'favorites',
      //   isChecked: false,
      // },
      // {
      //   id: 3,
      //   title: 'Мои группы',
      //   name: 'admins',
      //   isChecked: false,
      // },
    ],

    // переменные переключения между возможностью добавить в избранное и нумеацией
    showNumeration: true,
    showFavorites: false,
  },
  mutations: {
    setShowNumeration(state, value = true) {
      state.showNumeration = value;
    },

    setShowFavorites(state, value = true) {
      state.showFavorites = value;
    },

    resetFilter(state) {
      state.period = [
        {
          id: 1,
          title: 'День',
          settingName: 'day',
          checked: true,
        },
        {
          id: 2,
          title: 'Неделя',
          settingName: 'week',
          checked: false,
        },
        {
          id: 3,
          title: 'Месяц',
          settingName: 'month',
          checked: false,
        },
      ];
      state.type = [
        {
          id: 1,
          title: 'Все',
          settingName: 'all',
          checked: true,
        },
        {
          id: 2,
          title: 'Паблики',
          settingName: 'page',
          checked: false,
        },
        {
          id: 3,
          title: 'Группы',
          settingName: 'group',
          checked: false,
        },
        {
          id: 4,
          title: 'События',
          settingName: 'event',
          checked: false,
        },
      ];
      state.status = [
        {
          id: 1,
          title: 'Все группы',
          settingName: 'empty',
          checked: true,
        },
        {
          id: 2,
          title: 'Открытые',
          settingName: 'Открытая группа',
          checked: false,
        },
        {
          id: 3,
          title: 'Закрытые',
          settingName: 'Закрытая группа',
          checked: false,
        },
      ];
      state.checkButtons = [
        {
          id: 1,
          title: 'Только официальные',
          name: 'verification',
          isChecked: false,
        },
        {
          id: 2,
          title: 'Показывать позицию',
          name: 'indexes',
          isChecked: true,
        },
        {
          id: 3,
          title: 'Добавить в избранное',
          name: 'favorites',
          isChecked: false,
        },
      ];
    },
  },
  actions: {
  },
  getters: {
    getPeriod(state) {
      return state.period;
    },
    getType(state) {
      return state.type;
    },
    getStatus(state) {
      return state.status;
    },
    getCheckButtons(state) {
      return state.checkButtons;
    },
    getSaleButtons(state) {
      return state.checkButtonsSale;
    },

    // table params
    checkShowNumeration(state) {
      return state.showNumeration;
    },
    checkShowFavorites(state) {
      return state.showFavorites;
    },
  },
};
