export default {
  namespaced: true,
  state: {
    settings: false,
    adminWindowShow: false,
  },
  mutations: {
    setSettingsState(state, value = false) {
      state.settings = value;
    },
    setAdminState(state, value = false) {
      state.adminWindowShow = value;
    },
  },
  getters: {
    getSettingsState(state) {
      return state.settings;
    },
    getAdminVisible(state) {
      return state.adminWindowShow;
    },
  },
};
