<template>
  <div v-if="showAdminInfo" class="container">
    <a :href="showAdminInfo.admin_link" target="_blank" class="admin-info">
      <div class="admin-info__image-size">
        <img :src="showAdminInfo.admin_photo_link" class="admin-info__icon" />
      </div>

      <p>{{ `${showAdminInfo.admin_first_name} ${showAdminInfo.admin_second_name}` }}</p>
    </a>
    <!-- <p class="menu__text">Admins ID : {{ getAdminsId }}</p> -->

    <div class="close" @click="clearAdminParams"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  computed: mapGetters('vkRequests', ['getAdminsId', 'showAdminInfo']),
  methods: {
    ...mapMutations('vkRequests', ['resetAllParams', 'setAdminsId', 'setLoadPage']),
    ...mapActions('vkRequests', ['requestCommunities', 'requestAuditory']),

    clearAdminParams() {
      this.setAdminsId();
      this.resetAllParams();

      this.requestNewData();
    },

    async requestNewData() {
      this.setLoadPage('requestCommunities');
      await this.requestAuditory();
      this.requestCommunities();

      const newRoute = this.checkAuthRoute();
      this.$router.push(newRoute);
    },

    checkAuthRoute() {
      const { userId } = this.$route.params;
      // const name = userId ? 'user-communities' : 'communities';
      const name = 'communities';
      const params = userId ? { userId } : {};

      const newPath = {
        name,
        params,
      };

      return newPath;
    },
  },
};
</script>

<style lang="scss" scoped>
.container{
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 5px;
}
.close {
  position: relative;
  width: 25px;
  height: 25px;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
  &::before,
  &::after {
    position: absolute;
    content: " ";
    width: 5px;
    height: 25px;
    background-color: #333;
  }
  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}
.admin-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin: 0 5px 0 0;

  &__image-size {
    width: 50px;
    height: 50px;

    margin: 0 5px 0 0;
  }

  &__icon {
    width: 50px;
    height: 50px;
  }
}
</style>
