<template>
  <div
    ref="user-info"
    class="user-info"
    @click.prevent
    @mouseover="isElement = true"
    @mouseout="isElement = false"
  >
    <div class="user-info__email">
      <p class="user-info__email-text" :title="takeEmail">
        {{ takeEmail }}
      </p>
    </div>

    <hr class="line" />

    <div class="user-info__menu">
      <div class="user-info__link">
        <img src="/icons/common/user.svg" class="user-info__icon" />

        <a href="#" class="user-info__title" @click.prevent="setNewRoute()">{{ userMenu.name }} </a>
      </div>

      <div class="user-info__link">
        <img src="/icons/common/logout.svg" class="user-info__icon" />

        <a href="#" class="user-info__title" @click.prevent="makeLogout">
          {{ authList.exitPage }}</a
        >
      </div>
    </div>

    <hr class="line" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { authList } from '@/assets/js/values';

export default {
  data() {
    return {
      isElement: false,
      languages: {
        0: { name: 'Ru', isActive: true },
        1: { name: 'En', isActive: false },
      },

      authList,
      userMenu: { name: authList.userPage, route: 'user-panel' },
    };
  },

  computed: {
    ...mapGetters('auth', ['authParams']),

    takeEmail() {
      const { mail } = this.authParams;
      return mail;
    },
  },

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('vkRequests', ['requestCurrentPage']),

    makeLogout() {
      this.logout().then(() => {
        let newRoute = '';

        const logoutName = this.$route.name.replace('user-', '');
        const isRedirectToMainPage = this.checkRoute(this.$route.name);

        if (isRedirectToMainPage) {
          newRoute = 'communities';
        } else {
          newRoute = logoutName;
        }

        this.$router.push({ name: newRoute });
        this.requestCurrentPage();
        this.$emit('close');
      });
    },

    setNewRoute() {
      this.$router.push({ name: this.userMenu.route, params: { userId: this.authParams.user } });
      this.updateCurrentButton(this.userMenu.name);
      this.$emit('close');
    },

    updateCurrentButton(btnName) {
      this.userMenu.name = btnName === 'Личный кабинет' ? 'Главная страница' : 'Личный кабинет';
      this.userMenu.route = btnName === 'Личный кабинет' ? 'communities' : 'user-panel';
    },

    checkRoute(routerName) {
      switch (routerName) {
        case 'user-panel':
          return true;
        default:
          return false;
      }
    },

    closeWindow() {
      if (!this.isElement) {
        this.$emit('close');
      }
    },
  },

  mounted() {
    this.userMenu.name = this.$route.name === 'user-panel' ? 'Главная страница' : 'Личный кабинет';
    this.userMenu.route = this.$route.name === 'user-panel' ? 'communities' : 'user-panel';

    document.addEventListener('click', this.closeWindow);
  },
  unmounted() {
    document.removeEventListener('click', this.closeWindow);
  },
};
</script>

<style lang="scss" scoped>
.user-info {
  position: absolute;
  top: 40px;
  right: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // align-items: center;

  width: 205px;
  height: 138px;

  background: #efefef; /*  */
  box-shadow: 0px 6px 10px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  &__email-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    cursor: pointer;
  }

  // .user-info__email
  &__email {
    margin: 8px 0 8px 15px;

    text-align: start;
    font-size: 0.975rem;
    font-weight: 700;
    color: #2c3e50;
  }

  // .user-info__menu
  &__menu {
  }

  // .user-info__link
  &__link {
    display: flex;

    justify-content: flex-start;
    align-items: center;

    margin: 0px 15px;
  }

  // .user-info__icon
  &__icon {
    width: 16px;
    height: 16px;

    margin: 0 12px 0 0;
  }

  // .user-info__title
  &__title {
    font-size: 13px;
  }

  // .user-info__language
  &__language {
    display: flex;

    margin: 10px 0 10px 15px;
  }

  // .user-info__button
  &__button {
    display: flex;
    align-items: center;

    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;

    margin: 0 8px 0 0;

    text-decoration: none;
    color: #c5c5c5;

    &_active {
      color: #4db6bc;
    }
  }
}

.line {
  width: 90%;
  border-top: 1px solid #3e4751;
  margin: 0 auto;
  // margin: 5px 0;
}
</style>
