<template>
  <div class="menu-mobile">
    <div
      v-if="showAdminInfo !== null && showAdminInfo !== undefined"
      class="menu-mobile__container"
      @click="setAdminWindow"
    >
      <img src="/icons/common/user-icon.svg" class="menu-mobile__icon" />
    </div>

    <admin-panel
      v-if="showAdminInfo !== null && showAdminInfo !== undefined && getAdminVisible"
      class="admin-panel"
    ></admin-panel>

    <div class="menu-mobile__container" @click="setSettings">
      <img src="/icons/common/burger.svg" class="menu-mobile__icon" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AdminPanel from '../../display-info/admin/AdminPanel.vue';

export default {
  components: {
    AdminPanel,
  },

  computed: {
    ...mapGetters('vkRequests', ['getAdminsId', 'showAdminInfo']),
    ...mapGetters('vkConfiguration', ['getSettingsState', 'getAdminVisible']),
  },

  methods: {
    ...mapMutations('vkConfiguration', ['setSettingsState', 'setAdminState']),

    setAdminWindow() {
      const isShow = this.getAdminVisible;
      this.setAdminState(!isShow);
    },

    setSettings() {
      const isShow = this.getSettingsState;
      this.setSettingsState(!isShow);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 5px;

    // width: 25px;
    // height: 25px;
    width: 45px;
    height: 78px;
  }

  &__icon {
    // width: 100%;
    // height: 100%;
    width: 25px;
    height: 25px;
  }
}

.admin-panel {
  height: 54px;
  position: absolute;
  top: 56px;
  right: 0px;
  background: #dee5eb;
  z-index: 20;

  border: 1px solid #b2b5b8;

  padding: 10px;
}
</style>
