<template>
  <div class="close-icon" :style="cssVars"></div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333',
    },
  },
  computed: {
    cssVars() {
      return {
        '--bg-color': this.color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.close-icon {
  position: relative;
  width: 25px;
  height: 25px;
  opacity: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
  &::before,
  &::after {
    position: absolute;
    // left: 0;
    content: " ";
    height: 33px;
    width: 6px;
    background-color: #333;

    background-color: var(--bg-color);
  }
  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}
</style>
