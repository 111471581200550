import axios from 'axios';

// const ip = 'http://185.105.88.205:5000';
const ip = '';

const { CancelToken } = axios;
// const source = CancelToken.source();

export default {
  namespaced: true,
  state: {
    // request tokens for cancle
    tokenCommunities: null,
    tokenAdmin: null,
    tokenChanges: null,
    tokenCurrent: null,

    // set current choosen page
    loadPage: '',

    // helpers params
    animationStart: false,
    paginationAnimation: false,
    infinityAnimation: false,
    helpAnimation: false,

    adminInfoData: null,
    adminsId: '',

    // TODO need del  used for watch in pagination
    resertButtons: false,
    goFromAnotherPage: false,

    // static params
    // bad idea find bestpractise
    bufAuditory: 0,
    auditory: {
      diapazoneFrom: 0,
      diapazoneTo: 100000000,
      countFrom: 0,
      countTo: 100000000,
    },

    // for admin page
    subcount: {
      diapazoneFrom: 0,
      diapazoneTo: 100000000,
      countFrom: 0,
      countTo: 100000000,
    },

    growcount: {
      diapazoneFrom: 0,
      diapazoneTo: 100000000,
      countFrom: 0,
      countTo: 100000000,
    },

    reachcount: {
      diapazoneFrom: 0,
      diapazoneTo: 100000000,
      countFrom: 0,
      countTo: 100000000,
    },
    newRequest: false,

    // request inputText rename this
    choose: '',

    // setting from filter
    currentDate: 'day',
    groupType: 'all',
    statusType: '',
    // sort method фильтр;
    filtredParametr: 'sub',
    // по возрастанию или убыванию (t - возрастание, f - убывание)
    sortBy: 't',
    categoryes: [],
    verificator: '',
    // set buttons changed
    changesType: {
      name: true,
      ban: true,
      ava: false,
      close: false,
    },
    catalogs: '',
    searchByName: '',
    // TODO del, dont used now
    // request pages count
    paginationCount: 0,
    countFrom: 0,
    countTo: 49,
    // when get result request set from back max group or admins
    maxCount: 100,
    // in first request take max count adn set in pages
    // it need because max count with every request has new value
    countGroup: 0,
    // for pagination used
    maxButtons: 0,

    // result of request
    resultJSON: [],

    steckCals: [],
    userId: '',
  },

  mutations: {
    // TODO what i need reset mb not only pages
    resetJSON(state) {
      state.resultJSON = [];

      state.countFrom = 0;
      state.paginationCount = 0;
      state.countTo = 49;

      state.resertButtons = true;
    },
    resetAllParams(state) {
      state.resultJSON = [];
      state.maxButtons = 0;
      state.countGroup = 0;
      state.maxCount = 100;
      state.catalogs = '';
      state.sortBy = 't';
      state.categoryes = [];
      state.verificator = '';
      state.choose = '';

      state.currentDate = 'day';
      state.groupType = 'all';
      state.statusType = '';
      state.filtredParametr = 'sub';
      state.loadPage = '';

      // state.adminsId = '';
      // state.adminInfoData = null;

      state.countFrom = 0;
      state.paginationCount = 0;
      state.countTo = 49;

      state.resertButtons = true;
    },

    setAdminsId(state, value = null) {
      // console.log('admin is', value);
      if (value === null) {
        state.adminsId = '';
        state.adminInfoData = null;

        return;
      }

      state.adminsId = `&admin=${value}`;
    },

    setCurrentDate(state, date) {
      state.currentDate = date;
    },

    // set choose method for  pagination between search and standart request
    setSearchInput(state, value) {
      if (value === '') {
        state.choose = value;

        return;
      }

      state.choose = `&gname=${value}`;
    },

    setFiltredParametr(state, value) {
      state.filtredParametr = value;
    },

    setLoadPage(state, currentPage) {
      state.loadPage = currentPage;
    },
    // TODO used for watch when go from admin to communities
    // when watch groups admin
    setChangeAnotherPage(state) {
      state.goFromAnotherPage = !state.goFromAnotherPage;
    },
  },

  actions: {
    // not request used in double slider
    setAndRequestAuditory({ state, dispatch, commit }, newAuditory) {
      commit('resetJSON');

      state.auditory.countFrom = Number.parseInt(newAuditory.countFrom, 10);
      state.auditory.countTo = Number.parseInt(newAuditory.countTo, 10);

      dispatch(state.loadPage);
    },

    setAndRequestAdminsParams({ state, dispatch, commit }, newParams) {
      commit('resetJSON');
      // console.log('newParamsnewParams', newParams);
      state[newParams.name].countFrom = Number.parseInt(newParams.countFrom, 10);
      state[newParams.name].countTo = Number.parseInt(newParams.countTo, 10);

      state.newRequest = false;

      dispatch(state.loadPage);
    },

    // in new version api dont used
    // if dont used more than auditory need del
    async requestAllStaticParams({ state, dispatch }) {
      state.animationStart = true;
      await dispatch('requestAuditory');
    },

    async requestAdminStaticParams({ state }) {
      state.animationStart = true;
      const requestString = `${ip}/astat?time=${state.currentDate}`;

      await axios.get(requestString)
        .then((responce) => {
          // console.log('responce.data', `${ip}/astat?time=${state.currentDate}`, responce.data);
          state.subcount.diapazoneTo = Number.parseInt(responce.data.stat_count.max_subs_count, 10);
          state.subcount.countTo = Number.parseInt(responce.data.stat_count.max_subs_count, 10);

          state.growcount.diapazoneTo = Number.parseInt(
            responce.data.stat_count.max_grow_count, 10,
          );
          state.growcount.countTo = Number.parseInt(
            responce.data.stat_count.max_grow_count, 10,
          );
          state.growcount.diapazoneFrom = Number.parseInt(
            responce.data.stat_count.min_grow_count, 10,
          );
          state.growcount.countFrom = Number.parseInt(
            responce.data.stat_count.min_grow_count, 10,
          );

          state.reachcount.diapazoneTo = Number.parseInt(
            responce.data.stat_count.max_reach_count, 10,
          );
          state.reachcount.countTo = Number.parseInt(
            responce.data.stat_count.max_reach_count, 10,
          );

          state.newRequest = true;

          // state.maxCount = responce.data.stat_count.admins_count || 0;
        })
        .catch((err) => {
          console.log(err);
        });

      // await dispatch('requestSubcount');
      // await dispatch('requestGrowcount');
      // await dispatch('requestReachcount');
    },

    // in new version api dont used
    async requestAuditory({ state }) {
      const requestString = `${ip}/gstat?time=${state.currentDate}`;

      await axios.get(requestString)
        .then((responce) => {
          state.auditory.diapazoneTo = Number.parseInt(responce.data.stat_count.max_subs_count, 10);
          state.auditory.countTo = Number.parseInt(responce.data.stat_count.max_subs_count, 10);

          state.auditory.diapazoneFrom = 0;
          state.auditory.countFrom = 0;
          state.bufAuditory = Number.parseInt(responce.data.stat_count.max_subs_count, 10);
          // state.maxCount = responce.data.stat_count.groups_count || 0;
          state.categoryes = responce.data.stat_count.categories;
          // console.log('check min max', responce.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // requests filter here
    async requestByDate({ state, dispatch, commit }, date) {
      commit('resetJSON');
      if (state.currentDate === date) return;
      state.currentDate = date;
      const staticPage = state.loadPage === 'requestCommunities' ? 'requestAllStaticParams' : 'requestAdminStaticParams';
      await dispatch(staticPage);
      dispatch(state.loadPage);
    },

    async requestByType({ state, dispatch, commit }, type) {
      commit('resetJSON');

      if (state.groupType === type) return;

      state.groupType = type;

      dispatch(state.loadPage);
    },

    async requestByStatus({ state, dispatch, commit }, status) {
      commit('resetJSON');

      if (state.statusType === status) return;

      state.statusType = status === 'empty' ? '' : `&act=${status}`;

      dispatch(state.loadPage);
    },

    async requestByVerification({ state, dispatch, commit }, verificator) {
      commit('resetJSON');
      if (state.verificator === verificator) return;

      state.verificator = verificator ? '&ver=t' : '';

      dispatch(state.loadPage);
    },

    async requestByChangeChecked({ state, dispatch, commit }, parametrs) {
      commit('resetJSON');
      await parametrs.forEach((item) => {
        state.changesType[item.name] = item.isActive ? `&${item.name}=t` : '';
      });

      dispatch(state.loadPage);
    },

    async requestByCategory({ state, dispatch }, cat = '') {
      // await dispatch('cancleRequest', 'tokenCommunities');
      state.catalogs = cat === '' ? '' : `&act=${cat.toLowerCase()}`;
      await dispatch(state.loadPage);
    },

    // requests by sort
    async requestBySort({ state, dispatch, commit }, params) {
      commit('resetJSON');

      state.filtredParametr = params.filtredParametr;
      state.sortBy = params.sortBy ? 't' : 'f';
      dispatch(state.loadPage);
    },

    // main request
    cancleRequest({ state }, currentRequest) {
      if (state[currentRequest] !== null) {
        state[currentRequest].cancel('clodes request.');
        state.steckCals = [];
      }
    },

    // main requests pages
    requestCurrentPage({ state, dispatch }) {
      if (state.loadPage) {
        dispatch(state.loadPage);
      }
    },

    requestCommunities({ state, rootGetters }) {
      // console.log('calls');
      state.steckCals.push('calls');
      if (state.tokenAdmin !== null) state.tokenAdmin.cancel('Operation canceled by the user.');
      if (state.tokenChanges !== null) state.tokenChanges.cancel('Operation canceled by the user.');

      const communities = CancelToken.source();

      if (state.tokenCommunities !== communities && state.tokenCommunities !== null) {
        state.tokenCommunities.cancel('Operation canceled by the user.');
      }

      state.tokenCommunities = communities;
      state.animationStart = true;
      state.paginationAnimation = true;
      // TODO need del parametr set another method
      if (state.groupType !== 'group') state.statusType = '';
      const { userId } = rootGetters['auth/authParams'];
      let uid = '';
      if (userId) {
        uid = `&uid=${userId}`;
      }

      const requestString = `${ip}/getgroups?fcount=${state.countFrom}&tcount=${state.countTo}&par=${state.filtredParametr}&time=${state.currentDate}&rev=${state.sortBy}&fsub=${state.auditory.countFrom}&tsub=${state.auditory.countTo}&type=${state.groupType}${state.verificator}${state.statusType}${state.catalogs}${state.choose}${state.adminsId}${uid}`;

      const cancleToken = {
        cancelToken: state.tokenCommunities.token,
      };

      // console.log('REQUEEEST STRING', requestString);
      axios.get(requestString, cancleToken).then((responce) => {
        state.resultJSON = responce.data;
        // console.log('calls end', state.steckCals);
        state.steckCals.pop();
        if (state.steckCals.length === 0) {
          state.animationStart = false;
          state.paginationAnimation = false;
        }
      }).catch((error) => {
        if (error.message !== 'Operation canceled by the user.') {
          state.steckCals.pop();
          if (state.steckCals.length === 0) {
            state.animationStart = false;
            state.paginationAnimation = false;
          }
        }
      }).then(() => {
        // console.log('calls end', state.steckCals);
        state.steckCals.pop();
        if (state.steckCals.length === 0) {
          // state.animationStart = false;
          // state.paginationAnimation = false;
        }
      });
    },

    requestAdministrators({ state }) {
      if (state.tokenChanges !== null) state.tokenChanges.cancel('Operation canceled by the user.');
      if (state.tokenCommunities !== null) state.tokenCommunities.cancel('Operation canceled by the user.');

      const admins = CancelToken.source();

      if (state.tokenAdmin !== admins && state.tokenAdmin !== null) {
        state.tokenAdmin.cancel('Operation canceled by the user.');
      }

      state.tokenAdmin = admins;

      const cancelToken = {
        cancelToken: state.tokenAdmin.token,
      };

      state.animationStart = true;
      state.paginationAnimation = true;
      state.steckCals.push('calls');

      if (state.groupType !== 'group') state.statusType = '';

      const requestString = `${ip}/getadmins?fcount=${state.countFrom}&tcount=${state.countTo}&par=${state.filtredParametr}&fgrow=${state.growcount.countFrom}&tgrow=${state.growcount.countTo}&freach=${state.reachcount.countFrom}&treach=${state.reachcount.countTo}&fsub=${state.subcount.countFrom}&tsub=${state.subcount.countTo}&time=${state.currentDate}&rev=${state.sortBy}${state.choose}`;

      axios.get(requestString, cancelToken).then((responce) => {
        state.resultJSON = responce.data;
        // state.animationStart = false;
        // state.paginationAnimation = false;
        // console.log('DADA IS', requestString, responce.data);
      }).catch((error) => {
        console.log(error);
        if (error.message !== 'Operation canceled by the user.') {
          // state.animationStart = false;
          // state.paginationAnimation = false;
        }
        // state.animationStart = false;
        // state.paginationAnimation = false;
      }).then(() => {
        state.steckCals.pop();
        if (state.steckCals.length === 0) {
          state.animationStart = false;
          state.paginationAnimation = false;
        }
      });
    },

    requestChanges({ state }) {
      if (state.tokenAdmin !== null) state.tokenAdmin.cancel('Operation canceled by the user.');
      if (state.tokenCommunities !== null) state.tokenCommunities.cancel('Operation canceled by the user.');

      const changes = CancelToken.source();
      if (state.tokenChanges !== changes && state.tokenChanges !== null) {
        state.tokenChanges.cancel('Operation canceled by the user.');
      }

      state.tokenChanges = changes;

      const cancelToken = {
        cancelToken: state.tokenChanges.token,
      };

      state.animationStart = true;
      state.paginationAnimation = true;
      state.steckCals.push('calls');
      if (state.groupType !== 'group') state.statusType = '';

      const eachParam = Object.keys(state.changesType).reduce((previousValue, currentValue) => `${previousValue}&${currentValue}=${state.changesType[currentValue] ? 't' : 'f'}`, '');

      const requestString = `${ip}/getchanges?fcount=${state.countFrom}&tcount=${state.countTo}&type=${state.groupType}&fsub=${state.auditory.countFrom}&tsub=${state.auditory.countTo}${state.statusType}${state.choose}${state.catalogs}${eachParam}`;

      axios.get(requestString, cancelToken).then((responce) => {
        state.resultJSON = responce.data;
        // state.animationStart = false;
        // state.paginationAnimation = false;
        // console.log('CHANGESA', JSON.stringify(responce.data));
      }).catch((error) => {
        console.log(error);
        if (error.message !== 'Operation canceled by the user.') {
          // state.animationStart = false;
          // state.paginationAnimation = false;
        }
      }).then(() => {
        state.steckCals.pop();
        if (state.steckCals.length === 0) {
          state.animationStart = false;
          state.paginationAnimation = false;
        }
      });
    },

    // pagination here
    requestPage({ state, dispatch }, increment = 0) {
      state.resertButtons = false;

      if (increment === 1) {
        state.countFrom += 50;
        state.countTo = 50 + state.countTo > state.maxCount ? state.maxCount
          : 50 + state.countTo;
      }

      if (increment === 2) {
        state.countFrom += 100;
        state.countTo = 100 + state.countTo > state.maxCount ? state.maxCount
          : 100 + state.countTo;
      }

      if (increment === -2) {
        if (state.countTo === state.maxCount) {
          state.countTo = state.countFrom - 100 + 49;
        } else {
          state.countTo = state.countTo - 100 < 49 ? 49 : state.countFrom - 100;
        }

        state.countFrom = state.countFrom - 100 < 0 ? 0 : state.countFrom - 100;

        if (state.countFrom < 0) state.countFrom = 0;
        if (state.countTo < 49) state.countTo = 49;
        if (state.countFrom > 0 && state.countFrom < 49) {
          state.countFrom = 0;
          state.countTo = 49;
        }
      }

      if (increment === -1) {
        if (state.countTo === state.maxCount) {
          state.countTo = state.countFrom - 1;
        } else {
          state.countTo -= 50;
        }

        state.countFrom -= 50;
        if (state.countFrom < 0) state.countFrom = 0;
        if (state.countTo < 49) state.countTo = 49;
        if (state.countFrom > 0 && state.countFrom < 49) {
          state.countFrom = 0;
          state.countTo = 49;
        }
      }

      if (increment === -10) {
        state.countFrom = 0;
        state.countTo = 49;
      }

      if (increment === 10) {
        const calcStartCount = (+state.maxButtons - 1) * 50;
        state.countFrom = calcStartCount;
        state.countTo = state.maxCount;
      }

      state.paginationCount = state.countFrom;
      dispatch(state.loadPage);
    },

    requestPagePagination({ state, rootGetters }) {
      state.resertButtons = false;

      state.countFrom += 50;
      state.countTo += 50;

      if (state.groupType !== 'group') state.statusType = '';

      let requestString = '';
      let eachParam = '';
      state.infinityAnimation = true;

      const { userId } = rootGetters['auth/authParams'];
      let uid = '';
      if (userId) {
        uid = `&uid=${userId}`;
      }

      switch (state.loadPage) {
        case 'requestCommunities':
          requestString = `${ip}/getgroups?fcount=${state.countFrom}&tcount=${state.countTo}&par=${state.filtredParametr}&time=${state.currentDate}&rev=${state.sortBy}&fsub=${state.auditory.countFrom}&tsub=${state.auditory.countTo}&type=${state.groupType}${state.verificator}${state.statusType}${state.catalogs}${state.choose}${state.adminsId}${uid}`;

          axios.get(requestString).then((responce) => {
            state.resultJSON = state.resultJSON.concat(...responce.data);
            state.infinityAnimation = false;
          }).catch((error) => {
            console.log(error);
            state.infinityAnimation = false;
          });
          break;
        case 'requestAdministrators':
          if (state.groupType !== 'group') state.statusType = '';

          requestString = `${ip}/getadmins?fcount=${state.countFrom}&tcount=${state.countTo}&par=${state.filtredParametr}&fgrow=${state.growcount.countFrom}&tgrow=${state.growcount.countTo}&freach=${state.reachcount.countFrom}&treach=${state.reachcount.countTo}&fsub=${state.subcount.countFrom}&tsub=${state.subcount.countTo}&time=${state.currentDate}&rev=${state.sortBy}${state.choose}`;

          axios.get(requestString).then((responce) => {
            state.infinityAnimation = false;

            state.resultJSON = state.resultJSON.concat(...responce.data);
          }).catch((error) => {
            console.log(error);
            state.infinityAnimation = false;
          });
          break;
        default:
          if (state.groupType !== 'group') state.statusType = '';

          eachParam = Object.keys(state.changesType).reduce((previousValue, currentValue) => `${previousValue}&${currentValue}=${state.changesType[currentValue] ? 't' : 'f'}`, '');

          requestString = `${ip}/getchanges?fcount=${state.countFrom}&tcount=${state.countTo}&type=${state.groupType}&fsub=${state.auditory.countFrom}&tsub=${state.auditory.countTo}${state.statusType}${state.choose}${state.catalogs}${eachParam}`;

          axios.get(requestString).then((responce) => {
            state.resultJSON = state.resultJSON.concat(...responce.data);
            state.infinityAnimation = false;
          }).catch((error) => {
            console.log(error);
            state.infinityAnimation = false;
          });
      }
    },

    // used in admins page for check group admins
    async setAdminsId({ commit }, idUser) {
      commit('setAdminsId', idUser);
    },

    // TODO if remakes request this delete
    async paginationBySearch({ state, dispatch, rootGetters }, groupName) {
      // console.log('paginationBySearch');
      if (groupName.trim() === '') {
        dispatch(state.loadPage);
        return;
      }

      let requestString = '';

      if (state.groupType !== 'group') state.statusType = '';
      let eachParam = '';

      const { userId } = rootGetters['auth/authParams'];
      let uid = '';
      if (userId) {
        uid = `&uid=${userId}`;
      }

      switch (state.loadPage) {
        case 'requestCommunities':
          requestString = `${ip}/getgroup?gname=${groupName}fcount=${state.countFrom}&tcount=${state.countTo}&par=${state.filtredParametr}&time=${state.currentDate}&rev=${state.sortBy}&fsub=${state.auditory.countFrom}&tsub=${state.auditory.countTo}&type=${state.groupType}${state.verificator}${state.statusType}${state.catalogs}${state.choose}${state.adminsId}${uid}`;
          axios.get(requestString, {

          }).then((responce) => {
            state.resultJSON = state.resultJSON.concat(...responce.data);

            state.infinityAnimation = false;
          }).catch((error) => {
            console.log(error);
            state.infinityAnimation = false;
          });
          break;
        case 'requestAdministrators':
          requestString = `${ip}/getadmin?name=${groupName}fcount=${state.countFrom}&tcount=${state.countTo}&par=${state.filtredParametr}&fgrow=${state.growcount.countFrom}&tgrow=${state.growcount.countTo}&freach=${state.reachcount.countFrom}&treach=${state.reachcount.countTo}&fsub=${state.subcount.countFrom}&tsub=${state.subcount.countTo}&time=${state.currentDate}&rev=${state.sortBy}${state.choose}`;
          axios.get(requestString, {

          }).then((responce) => {
            state.resultJSON = state.resultJSON.concat(...responce.data);

            state.infinityAnimation = false;
          }).catch((error) => {
            console.log(error);
            state.infinityAnimation = false;
          });
          break;
        default:
          eachParam = Object.keys(state.changesType).reduce((previousValue, currentValue) => `${previousValue}&${currentValue}=${state.changesType[currentValue] ? 't' : 'f'}`, '');

          requestString = `${ip}/getchange?name=${groupName}fcount=${state.countFrom}&tcount=${state.countTo}&type=${state.groupType}&fsub=${state.auditory.countFrom}&tsub=${state.auditory.countTo}${state.statusType}${state.choose}${state.catalogs}${eachParam}`;

          axios.get(requestString, {

          }).then((responce) => {
            state.resultJSON = state.resultJSON.concat(...responce.data);

            state.infinityAnimation = false;
          }).catch((error) => {
            console.log(error);
            state.infinityAnimation = false;
          });
      }
    },

    // TODO dont used now
    async requestBySearch({ state, dispatch }) {
      dispatch(state.loadPage);
    },
  },

  getters: {
    getCurrentDate(state) {
      return state.currentDate;
    },
    // when get result request set from back max group or admins
    getResultCommunities(state) {
      const buffer = state?.resultJSON.pop() || '';
      // console.log('?.stat_count', buffer);
      state.maxCount = buffer?.groups_count || 0;

      if (state.adminsId) {
        const AdminInfo = state.resultJSON.pop() || '';

        state.adminInfoData = AdminInfo || null;
      }
      // console.log('FROM GETTER  REQUEST', state.resultJSON);
      return state.resultJSON;
    },

    showAdminInfo(state) {
      // console.log('gets in vk menu work');
      return state.adminInfoData;
    },
    // at first request set max admins or groups in page
    getGroupCount(state) {
      if (state.countFrom === 0 && state.countTo <= 50) {
        state.countGroup = state.maxCount;
      }

      return state.countGroup;
    },
    // static takes category
    getCategory(state) {
      // console.log('CHECK CATEGORIES', state.categoryes);
      return state.categoryes;
    },
    // used in slider set
    getAuditoryCount(state) {
      return state.auditory;
    },

    // for calc pagination used
    getMaxButtons(state) {
      if (state.countFrom === 0 && state.countTo <= 50) {
        state.maxButtons = Math.trunc((state.maxCount - 1) / 50) + 1;
      }

      return state.maxButtons;
    },
    // for position
    getPosition(state) {
      return state.paginationCount;
    },
    // used fow watch in double slider
    calcForWatch(state) {
      return state.bufAuditory;
    },
    // helpers params
    getAnimation(state) {
      return state.animationStart;
    },

    getPaginationAnimation(state) {
      return state.paginationAnimation;
    },

    getInfinityAnimaton(state) {
      return state.infinityAnimation;
    },

    getAdminsId(state) {
      // console.log('gets in vk menu work');
      if (state.adminsId !== '') {
        const tt = state.adminsId.split('=');
        // console.log('gets in vk menu work', tt);
        return tt[1];
      }

      return state.adminsId;
    },
    // watch if this request by admins group communities
    checkRoutePage(state) {
      return state.goFromAnotherPage;
    },
    // used for watch
    needResetButton(state) {
      return state.resertButtons;
    },

    getAdminStaticParams(state) {
      const result = {
        subcount: state.subcount,
        growcount: state.growcount,
        reachcount: state.reachcount,
        newRequest: state.newRequest,
      };

      return result;
    },

    getHelpAnimation(state) {
      return state.helpAnimation;
    },
  },
};
