import { createStore } from 'vuex';
import vkRequests from './modules/vkRequests';
import vkConfiguration from './modules/vkConfiguration';
import vkValues from './modules/vkValues';
import adminRequest from './modules/adminRequest';
import auth from './modules/auth';
import userPanel from './modules/userPanel';
import pagination from './modules/pagination';
import publicSale from './modules/publicSale';

export default createStore({
  modules: {
    vkRequests,
    vkConfiguration,
    vkValues,
    adminRequest,
    auth,
    userPanel,
    pagination,
    publicSale,
  },
});
