import userGroups from '@/api/userGroups';

const LIST_LOAD = 'load';
const LIST_CREATE = 'create';
const LIST_DELETE = 'delete';
const LIST_UPDATE = 'update';
const LIST_UPDATE_PARAM = 'updateParam';
const LIST_ADD_GROUP = 'addGroup';
const LIST_DEL_GROUP = 'delGroup';
const FAVORITE_DEL_GROUP = 'delFavoritesGroup';
const LIST_UPDATE_GROUP = 'updateGroup';
const FIND_LIST_ID = 'findId';
const RESET_GROUP_LIST = 'resetGroups';
const RESET_LIST = 'clearGroupsList';

export default {
  namespaced: true,
  state: {
    groupList: [],
  },

  mutations: {
    [LIST_LOAD](state, list) {
      // console.log('LIST_LOAD', list);
      const teamplates = list;
      teamplates.forEach((el, index) => {
        teamplates[index].isOpen = false;
        teamplates[index].readonly = true;
        teamplates[index].id = el.list_id;
        teamplates[index].title = el.list_name;
        teamplates[index].table = [];
        teamplates[index].isSelectShow = false;
        teamplates[index].showAnimation = false;
      });

      state.groupList = teamplates;
      // console.log('LIST_LOAD', state.groupList);
    },

    [LIST_DELETE]: (state, indexPosition) => {
      state.groupList.splice(indexPosition, 1);
    },

    [LIST_CREATE]: (state, newList) => {
      // console.log('LIST ISSS', newList, state.groupList);
      state.groupList.push(newList);
    },

    [LIST_UPDATE]: (state, { index, title }) => {
      state.groupList[index].title = title;
    },

    [LIST_UPDATE_GROUP]: (state, { table, tableName }) => {
      let index = -1;

      state.groupList.forEach((el, ind) => {
        if (el.title === tableName) {
          index = ind;
        }
      });

      state.groupList[index].table = table;
      // console.log('LIST_UPDATE_GROUP', table, index, state.groupList[index]);
    },

    [LIST_DEL_GROUP]: (state, { gid, lid }) => {
      // console.log('LIST_DEL_GROUP', state.groupList, gid, lid);
      const indLid = state.groupList.findIndex((el) => el.id === lid);
      if (indLid === -1) { return; }
      // console.log('LIST_DEL_GROUP indLid', indLid, state.groupList[indLid]);
      const index = state.groupList[indLid].table.findIndex((el) => el.id === gid);
      // console.log('LIST_DEL_GROUP indLidindLid', index);
      if (index !== -1) {
        state.groupList[indLid].table.splice(index, 1);
      }
    },

    [LIST_UPDATE_PARAM]: (state, { index, param, state: status }) => {
      state.groupList[index][param] = status;
    },

    [RESET_GROUP_LIST]: () => {
      // if (state.groupList.length !== 0) {
      //   state.groupList.forEach((el, ind) => {
      //     state.groupList[ind].isOpen = false;
      //   });
      //   // console.log('state.groupList', state.groupList);
      // }
    },

    [RESET_LIST]: (state) => {
      state.groupList = [];
    },
  },

  actions: {
    [LIST_LOAD]({ commit, rootGetters }) {
      if (!rootGetters['auth/isAuthenticated']) {
        return;
      }

      const { userId } = rootGetters['auth/authParams'];
      userGroups.getGroupList(userId).then((res) => {
        if (res.error) {
          return;
        }
        commit(LIST_LOAD, res);
      });
    },

    [LIST_DELETE]: ({ state, commit }, index) => new Promise((res, rej) => {
      const { id, title } = state.groupList[index];

      userGroups.deleteList({ lid: id, name: title }).then((resp) => {
        commit(LIST_DELETE, index);
        res(resp.data);
      }).catch((error) => {
        rej(error);
      });
    }),
    [LIST_CREATE]: ({ commit, rootGetters }, newList) => new Promise((res, rej) => {
      const { title: name, id: gid } = newList;
      const { userId: id } = rootGetters['auth/authParams'];
      const resultList = newList;

      userGroups.createNewList({ name, id, gid }).then((resp) => {
        // console.log('create', resp);
        resultList.id = resp?.list_id ?? 0;
        commit(LIST_CREATE, resultList);
        res(resp.data);
      }).catch((error) => {
        console.log(error);
        rej(error);
      });
    }),
    [LIST_UPDATE]: ({ state, commit }, { index }) => new Promise((res, rej) => {
      const { id: lid, title: name } = state.groupList[index];

      userGroups.updateList({ lid, name }).then((response) => {
        // console.log('create', response);
        commit(LIST_UPDATE, { index, title: name });

        res(response.data);
      }).catch((error) => {
        console.log(error);
        rej(error);
      });
    }),

    [LIST_ADD_GROUP]: ({ dispatch }, groupId) => new Promise((res, rej) => {
      dispatch(FIND_LIST_ID).then((resp) => {
        const idList = resp;
        // console.log('dispatch userGroups', idList);
        userGroups.addGroupInList({ gid: groupId, lid: idList }).then((response) => {
          // console.log('create', response);
          res(response.data);
        }).catch((error) => {
          console.log(error);
          rej(error);
        });
      });
    }),

    [LIST_DEL_GROUP]: ({ commit }, { gid, lid }) => new Promise((res, rej) => {
      userGroups.deleteGroupFromList({ gid, lid }).then(() => {
        // console.log('delete', response, gid, lid);
        commit(LIST_DEL_GROUP, { gid, lid });
        res();
      }).catch((error) => {
        console.log(error);
        rej(error);
      });
    }),

    [FAVORITE_DEL_GROUP]: ({ dispatch }, gid) => new Promise((res, rej) => {
      dispatch(FIND_LIST_ID).then((resp) => {
        const idList = resp;

        dispatch(LIST_DEL_GROUP, { gid, lid: idList }).then(() => res()).catch(() => rej());
      });
    }),

    [LIST_UPDATE_GROUP]: ({ commit }, param) => {
      commit(LIST_UPDATE_GROUP, param);
    },

    [LIST_UPDATE_PARAM]: ({ commit }, param) => {
      // console.log('LIST_UPDATE_PARAM', state.groupList, param);
      commit(LIST_UPDATE_PARAM, param);
    },

    [FIND_LIST_ID]: ({ state }) => new Promise((res, rej) => {
      const element = state.groupList.find((el) => el.title === 'Избранное');
      // console.log('dispatch find userGroups', element);
      if (element !== undefined) {
        res(element.id);
      } else {
        rej();
      }
    }),
  },

  getters: {
    getGroupList: (state) => state.groupList
    ,
  },
};
