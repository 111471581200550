<template>
  <div class="main">
    <div class="menu">
      <div
      v-for="item in routerLinks"
      :key="item.id"
      class="menu__link">
        <a
        v-if="item.visible"
        :class="{ 'active-button': item.id === routerActive }"
        @click="goToActivePage(item)"
      >
        {{ item.name }}
      </a>
      </div>

      <menu-mobile v-if="mobileLanding" class="menu__admin"></menu-mobile>
      <admin-panel v-else class="menu__admin"></admin-panel>
    </div>

    <router-view class="content" />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import MenuMobile from '@/components/menu/mobile/MenuMobile.vue';
import AdminPanel from '@/components/display-info/admin/AdminPanel.vue';

export default {
  components: {
    MenuMobile,
    AdminPanel,
  },

  computed: {
    ...mapGetters('vkRequests', ['getAdminsId', 'showAdminInfo']),
    ...mapGetters('auth', ['isAuthenticated']),
  },

  watch: {
    $route: {
      handler(to) {
        this.routerLinks.forEach((item) => {
          if (to.name.includes(item.route)) {
            this.routerActive = item.id;
          }
        });
      },
      immediate: true,
    },
    isAuthenticated(newState) {
      this.setVisibleSalePage(newState);
    },
  },

  data() {
    return {
      routerLinks: [
        {
          id: 0,
          name: 'Сообщества',
          route: 'communities',
          link: {
            name: 'communities',
          },
          visible: true,
        },
        {
          id: 1,
          name: 'Изменения',
          route: 'changes',
          link: { name: 'changes' },
          visible: true,
        },
        {
          id: 2,
          name: 'Администраторы',
          route: 'administrator',
          link: { name: 'administrator' },
          visible: true,
        },
        {
          id: 3,
          name: 'Продажа пабликов',
          route: 'public',
          link: { name: 'public' },
          visible: true,
        },
      ],

      routerActive: 0,
      showModal: false,
      mobileLanding: false,
    };
  },
  methods: {
    ...mapMutations('vkRequests', ['resetAllParams', 'setLoadPage']),
    ...mapActions('vkRequests', ['requestCommunities', 'requestAuditory', 'requestCategory']),
    ...mapMutations('vkConfiguration', ['setSettingsState', 'setAdminState']),

    goToActivePage(item) {
      this.routerActive = item.id;
      this.$router.push({ name: item.route });
    },

    // set burger menu when
    resise() {
      if (window.innerWidth <= 1300) {
        this.mobileLanding = true;

        return;
      }

      this.mobileLanding = false;
      this.setSettingsState(false);
      this.setAdminState(false);
    },

    setVisibleSalePage(stateButton = true) {
      this.routerLinks.forEach((el, ind) => {
        if (el.route === 'public') {
          this.routerLinks[ind].visible = stateButton;
        }
      });
    },
  },
  created() {
    if (!this.$store.getters['auth/isAuthenticated']) {
      this.setVisibleSalePage(false);
    }
  },
  mounted() {
    window.addEventListener('resize', this.resise);

    this.resise();
  },

  unmounted() {
    window.removeEventListener('resize', this.resise);
  },
};
</script>

<style lang="scss" scoped>
//TODO need refactoring
.main {
  display: flex;
  flex-direction: column;

  border: 1px solid #c4cde0;
}

.menu {
  display: flex;
  position: relative;

  padding: 20px 0;

  align-items: center;
  position: relative;

  background: #f1f1f1;
  border: 1px solid #fff;
  border-bottom: 1px solid #d8dfea;
  overflow: hidden;

  // .menu__link
  &__link {
    margin: 0 10px;
    padding: 0 8px;

    box-sizing: border-box;
    border: 1px solid transparent;

    cursor: pointer;
  }

  &__admin {
    position: absolute;
    right: 0px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: #45688e;
    background: #f1f1f1;
  }

  &__text {
    margin: 0 5px 0 0;
  }

  &__modal {
    position: absolute;
    right: 0;
  }
}

.active-button {
  background: #5e82a8;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.6) inset;
  border: 1px solid #383f48;

  color: #fff;
  text-decoration: underline;
  padding: 0 8px;
}

.close {
  position: relative;
  // right: 0;
  // top: 0;
  width: 25px;
  height: 25px;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
  &::before,
  &::after {
    position: absolute;
    left: 10px;
    content: " ";
    width: 5px;
    height: 25px;
    background-color: #333;
  }
  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}
.admin-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin: 0 5px 0 0;

  &__image-size {
    width: 50px;
    height: 50px;

    margin: 0 5px 0 0;
  }

  &__icon {
    width: 50px;
    height: 50px;
  }
}
</style>
