<template>
  <div :style="{ width: width }" class="tooltip">
    <plaintext class="tooltip__title">
      {{ text }}
    </plaintext>

    <div class="tooltip__arrow"></div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'auto',
    },

    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  position: relative;

  height: auto;
  background: #d8d8d8;
  border: 1px solid #45688e;
  border-radius: 10px;
  // .tooltip__title
  &__title {
    color: #45688e;
    font-size: 0.975rem;
    line-height: 1.25rem;
  }

  &__arrow {
    display: block;
    content: "";

    position: absolute;
    top: -41px;
    left: 5px;

    border-top: 20px solid transparent;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 19px solid #c4c2c2;
  }
}
</style>
