<template>
  <div id="container" class="page">
    <div class="white-space" id="header"></div>

    <header class="header">
      <nav v-if="!userPage" class="menu">
        <a href="#" class="button" :class="{ 'active-button': isButtonActive }" @click.prevent>
          Вконтакте
        </a>

        <auth-page class="auth-page"></auth-page>
      </nav>

      <nav v-else class="menu">
        <auth-page class="auth-page"></auth-page>
      </nav>
    </header>

    <section class="main-container">
      <router-view />
    </section>
  </div>
</template>

<script>
import AuthPage from '@/components/common/authentication/AuthPanel.vue';

export default {
  components: {
    AuthPage,
  },
  computed: {
    userPage() {
      return this.$route.name === 'user';
    },
  },
  data() {
    return {
      isButtonActive: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: grid;
  grid-template-rows: 20px 60px calc(100vh - 80px);
  grid-template-columns: 100%;
  grid-template-areas:
    "zero"
    "header"
    "main";

  max-width: 2500px;
  // height: 100%;

  margin: 0 auto 0;
  overflow: auto;
  // position: relative;
  // border: 1px solid #c4cde0;
}
.header {
  grid-area: header;
  height: 100%;

  background: #f1f1f1;

  // border: 1px solid #fff;
  // border-bottom: 1px solid #d8dfea;
  box-sizing: border-box;

  border-left: 1px solid #c4cde0;
  border-right: 1px solid #c4cde0;
  border-top: 1px solid #c4cde0;
}
.white-space {
  grid-area: zero;
  // position: sticky;
  // position: -webkit-sticky;
  // top: 0;

  content: "";
  width: 100%;
  height: 100%;
  // position: relative;
  display: flex;
  // opacity: 0;
}

.main-container {
  grid-area: main;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 145px;
  height: 45px;

  padding: 0;
  margin: 0 0 0 11px;
}

.active-button {
  background-color: #5e82a8;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.6) inset;
  border: 1px solid #383f48;

  color: #fff;
  text-decoration: underline;
}

.auth-page {
  margin: 0 5px 0 auto;
}
@media (max-width: 2500px) {
  .page {
    display: grid;
    grid-template-rows: 20px 60px calc(100vh - 80px);
    grid-template-areas:
      "zero"
      "header"
      "main";

    max-width: 90%;

    margin: 0 auto 0;
    overflow: auto;
    // border: 1px solid #c4cde0;
  }

  .white-space {
    grid-area: zero;

    content: "";
    width: 100%;
    height: 100%;
  }

  .header {
    grid-area: header;
    height: 100%;

    background: #f1f1f1;

    // border: 1px solid #fff;
    // border-bottom: 1px solid #d8dfea;
    box-sizing: border-box;

    border-left: 1px solid #c4cde0;
    border-right: 1px solid #c4cde0;
    border-top: 1px solid #c4cde0;
  }
  .main-container {
    grid-area: main;

    // border: 1px solid #c4cde0;
  }

  .menu {
    display: flex;
    align-items: center;

    height: 100%;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 145px;
    height: 45px;

    padding: 0;
    margin: 0 0 0 11px;
  }

  .active-button {
    background-color: #5e82a8;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.6) inset;
    border: 1px solid #383f48;

    color: #fff;
    text-decoration: underline;
  }
}

// @media screen and (max-width: 1920px) {
//   .page {
//     //margin: 20px 0 0;
//     //max-width: 100%;
//   }
// }
// 1200px
@media screen and (max-width: 1400px) {
  .page {
    // margin: 20px 0 0;
    max-width: 100%;
  }
}
</style>
