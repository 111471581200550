import axios from 'axios';

export default class publicSale {
  ip = '';

  filter = {
    countFrom: 0,
    countTo: 49,
    filtredParametr: 'sub',
    sortBy: '&rev=t',
    auditoryСountFrom: 0,
    auditoryСountTo: 100000000,
    auditoryDiapazoneFrom: 0,
    auditoryDiapazoneTo: 100000000,
    groupType: 'all',
    verificator: '', // dont used now
    statusType: '', // dont used now
    catalogs: '',
    choose: '',
    lid: '&lid=',
    currentDate: 'month',
  };

  categoryes= [];

  constructor(ip = '') {
    this.ip = ip;
  }

  resetInput(inputName) {
    const countTo = `${inputName}СountTo`;
    const countFrom = `${inputName}СountFrom`;

    this.filter[countFrom] = 0;
    this.filter[countTo] = 100000000;
  }

  // Методы для взаимодействия с группами для страницы продаж
  addGroupBySale(formData) {
    return new Promise((res, rej) => {
      axios.post(`${this.ip}/salelist/addgroup`, formData).then((responce) => {
        res(responce.data);
      }).catch((err) => {
        rej(err);
      });
    });
  }

  deleteGroupBySale({ gid, uid }) {
    return new Promise((res, rej) => {
      axios.get(`${this.ip}/salelist/delgroup?gid=${gid}&uid=${uid}`).then((responce) => {
        res(responce.data);
      }).catch((err) => {
        rej(err);
      });
    });
  }

  async requestAuditory() {
    const requestString = `${this.ip}/gstat?time=${this.filter.currentDate}`;

    await axios.get(requestString)
      .then((responce) => {
        this.filter.auditoryDiapazoneTo = Number.parseInt(
          responce.data.stat_count.max_subs_count, 10,
        );
        // this.filter.auditoryСountTo = Number.parseInt(
        //   responce.data.stat_count.max_subs_count, 10,
        // );

        this.filter.auditoryDiapazoneFrom = 0;
        // this.filter.auditoryСountFrom = 0;
        // this.bufAuditory = Number.parseInt(responce.data.stat_count.max_subs_count, 10);
        // this.maxCount = responce.data.stat_count.groups_count || 0;
        this.categoryes = responce.data.stat_count.categories;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  updateCountToFrom() {
    this.filter.countFrom += 50;
    this.filter.countTo += 50;
  }

  resetCountToFrom() {
    this.filter.countFrom = 0;
    this.filter.countTo = 49;
  }

  takeGroupsForSale(uid = '') {
    // update categoryes
    this.requestAuditory();

    if (this.filter.groupType !== 'group') {
      this.filter.statusType = '';
    }

    let requestUid = '';
    if (uid !== '') {
      requestUid = `&uid=${uid}`;
    }

    const requestString = `${this.ip}/salelist/get?fcount=${this.filter.countFrom}&tcount=${this.filter.countTo}&par=${this.filter.filtredParametr}${this.filter.sortBy}&fsub=${this.filter.auditoryСountFrom}&tsub=${this.filter.auditoryСountTo}&type=${this.filter.groupType}${requestUid}${this.filter.verificator}${this.filter.statusType}${this.filter.catalogs}${this.filter.choose}`;
    return new Promise((res, rej) => {
      axios.get(requestString).then((responce) => {
        res(responce.data);
      }).catch((err) => {
        rej(err);
      });
    });
  }

  takeAdminGroupsForSale(uid) {
    return new Promise((res, rej) => {
      axios.get(`${this.ip}/salelist/getmy?uid=${uid}`).then((responce) => {
        res(responce.data);
      }).catch((err) => {
        rej(err);
      });
    });
  }

  async updateByFilter(groupName, settingName) {
    this.resetCountToFrom();

    if (groupName === 'input') {
      const { countFrom, countTo } = settingName;

      if (countFrom === '' && countTo === '') {
        this.resetInput('auditory');

        this.requestAuditory();
        return;
      }

      if (+countTo < +countFrom) {
        this.filter.auditoryСountFrom = +countFrom;
        this.filter.auditoryСountTo = +countFrom;
        return;
      }

      this.filter.auditoryСountFrom = +countFrom;
      this.filter.auditoryСountTo = +countTo;
      return;
    }

    switch (groupName) {
      case 'date':
        this.filter.currentDate = settingName;
        break;
      case 'type':
        if (this.filter.groupType === settingName) return;
        this.filter.groupType = settingName;
        break;
      case 'verification':
        this.filter.verificator = settingName ? '&ver=t' : '';
        break;
      case 'catalog':
        this.filter.catalogs = settingName === '' ? '' : `&act=${settingName.toLowerCase()}`;
        break;
      default:
        if (this.filter.statusType === settingName) return;

        this.filter.statusType = settingName === 'empty' ? '' : `&act=${settingName}`;
        break;
    }
  }

  updateFilterSort(filtredParametr, sortBy = '') {
    this.resetCountToFrom();

    this.filter.filtredParametr = filtredParametr;
    this.filter.sortBy = sortBy === 't' ? '&rev=t' : '';
  }

  get category() {
    return this.categoryes;
  }

  get sort() {
    return {
      filtredParametr: this.filter.filtredParametr,
      sortBy: this.filter.sortBy === '&rev=t' ? 't' : '',
    };
  }

  // методы для работы непосредственно с группами на странице
  toogleFavoriteGroup(params, isAddGroup = true) {
    if (isAddGroup) {
      this.addFavoriteGroup(params);
      return;
    }
    this.delFavoriteGroup(params);
  }

  addFavoriteGroup({ gid, uid }) {
    return new Promise((res, rej) => {
      axios.get(`${this.ip}/salelist/favorite/add?gid=${gid}&uid=${uid}`).then((responce) => {
        res(responce.data);
      }).catch((err) => {
        rej(err);
      });
    });
  }

  delFavoriteGroup({ gid, uid }) {
    return new Promise((res, rej) => {
      axios.get(`${this.ip}/salelist/favorite/del?gid=${gid}&uid=${uid}`).then((responce) => {
        res(responce.data);
      }).catch((err) => {
        rej(err);
      });
    });
  }

  getFavoriteGroup(uid) {
    return new Promise((res, rej) => {
      axios.get(`${this.ip}/salelist/favorite/get?uid=${uid}`).then((responce) => {
        res(responce.data);
      }).catch((err) => {
        rej(err);
      });
    });
  }
}
